export const calendarFields = [
    {
        value: 'Tipo de Evento',
        name: 'type_event',
    },
    {
        value: 'Estado de la sesión',
        name: 'status_session',
    },
    {
        value: 'Título del módulo',
        name: 'module',
    },
    {
        value: 'Código de sesión',
        name: 'code_session',
    },
    {
        value: 'Título de evento / Sesión',
        name: 'title_session',
    },
    {
        value: 'Tipo de evento / Sesión',
        name: 'type_program',
    },
    {
        value: 'Descripción',
        name: 'description',
    },
    {
        value: 'Desarrollo de sesión',
        name: 'summary',
    },
    {
        value: '¿Asistencia tomada?',
        name: 'is_save',
    },
    {
        value: 'Colegio',
        name: 'school',
    },
    {
        value: 'Grado',
        name: 'grade',
    },
    {
        value: 'Grupo de formación',
        name: 'training_group',
    },
    {
        value: 'Grupo de beneficiarios',
        name: 'group_beneficiaries',
    },
    {
        value: 'Mentor',
        name: 'mentor',
    },
    {
        value: 'Monitor',
        name: 'monitor',
    },
    {
        value: 'Mentor Individual',
        name: 'individual_mentor',
    },
    {
        value: 'Fecha Inicio',
        name: 'start',
    },
    {
        value: 'Fecha Fin',
        name: 'end',
    },
    {
        value: 'Observaciones a la metodología',
        name: 'observations',
    },
    {
        value: 'Comentarios de la sesión',
        name: 'comments',
    },
    {
        value: 'Participantes',
        name: 'users'
    },
];

export const beneficiaryFields = [
    {
        value: 'Tipo de beneficiario',
        name: 'type',
    },
    {
        value: 'Identificación del beneficiario',
        name: 'bn_identification',
    },
    {
        value: 'Nombre del beneficiario',
        name: 'bn_first_name',
    },
    {
        value: 'Apellido del beneficiario',
        name: 'bn_last_name',
    },
    {
        value: 'Fecha de nacimiento',
        name: 'birthday',
    },
    {
        value: 'Autorización uso de datos personales',
        name: 'can_use_img',
    },
    {
        value: '¿Diligenció Autorización?',
        name: 'diligence_authorization',
    },
    {
        value: 'Autorización de Tratamiento de Datos Personales',
        name: 'treatment_personal',
    },
    {
        value: 'Autorización Especial',
        name: 'special_authorization',
    },
    { value: 'Estado Teórico', name: 'status' },
    {
        value: 'Razón de deserción Teórico',
        name: 'desertion_reason',
    },
    { value: 'Estado Práctico', name: 'status_practice' },
    {
        value: 'Razón de abandono Práctico',
        name: 'desertion_reason_practice',
    },
    { value: 'Estado Nivelación', name: 'status_leveling' },
    {
        value: 'Razón de deserción Nivelación',
        name: 'desertion_reason_leveling',
    },
    {
        value: 'Sexo',
        name: 'gender',
    },
    {
        value: 'Parentesco / relación',
        name: 'kinship',
    },
    {
        value: 'Profesión / Ocupación',
        name: 'profession',
    },
    {
        value: 'Celular',
        name: 'cellphone',
    },
    {
        value: 'Correo',
        name: 'email',
    },
    {
        value: 'Instagram',
        name: 'instagram',
    },
    {
        value: 'País',
        name: 'country',
    },
    {
        value: 'Departamento',
        name: 'department',
    },
    {
        value: 'Municipio',
        name: 'city',
    },
    {
        value: 'Dirección',
        name: 'address',
    },
    {
        value: 'Colegio',
        name: 'school',
    },
    {
        value: 'Jornada',
        name: 'workday',
    },
    {
        value: 'Grado',
        name: 'school_grade',
    },
    {
        value: 'Salón',
        name: 'school_hall',
    },
    {
        value: 'Nivel',
        name: 'level',
    },
    {
        value: 'No. Madres',
        name: 'n_mothers',
    },
    {
        value: 'No. Padres',
        name: 'n_fathers',
    },
    {
        value: 'No. Madrastras',
        name: 'n_stepmothers',
    },
    {
        value: 'No. Padrastros',
        name: 'n_stepfathers',
    },
    {
        value: 'No. hermanos y hermanas',
        name: 'n_brothers_sisters',
    },
    {
        value: 'No. Abuelas',
        name: 'n_grandmothers',
    },
    {
        value: 'No. Abuelos',
        name: 'n_grandparents',
    },
    {
        value: 'No. Tíos / Tías',
        name: 'n_uncles',
    },
    {
        value: 'Otros ',
        name: 'others',
    },
    {
        value: 'No. Otros familiares',
        name: 'n_others',
    },
    {
        value: '¿Padres viven juntos?',
        name: 'parents_together',
    },
    {
        value: 'Riesgos',
        name: 'risks',
    },
    {
        value: '¿Tiene dispositivo?',
        name: 'has_device',
    },
    {
        value: 'Tipo de dispositivo',
        name: 'type_device',
    },
    {
        value: 'Tipo de conexión',
        name: 'type_network',
    },
    {
        value: '¿Debe Proyecto Social?',
        name: 'social_project',
    },
    {
        value: 'Caracterización FAMOF',
        name: 'famof',
    },
    {
        value: '¿Hace cuánto viven juntos?',
        name: 'how_long_together',
    },
    {
        value: 'Vinculación especial',
        name: 'special_boding',
    },
    {
        value: 'Identificación del acudiente',
        name: 'id_legal_guardian',
    },
    {
        value: 'Padrino',
        name: 'godfather',
    },
];

export const schoolFields = [
    {
        value: 'NIT',
        name: 'nit',
    },
    {
        value: 'Nombre',
        name: 'name',
    },
    {
        value: 'Código del colegio',
        name: 'code',
    },
    {
        value: 'Teléfono',
        name: 'telephone',
    },
    {
        value: 'Sitio Web',
        name: 'web_site',
    },
    {
        value: 'País',
        name: 'country',
    },
    {
        value: 'Departamento',
        name: 'department',
    },
    {
        value: 'Municipio',
        name: 'city',
    },
    {
        value: 'Dirección',
        name: 'address',
    },
    {
        value: 'Tamaño de colegio',
        name: 'range_size',
    },
    {
        value: 'Estado del colegio',
        name: 'status',
    },
    {
        value: 'Jornada',
        name: 'workday',
    },
    // {
    //     value: '¿Debe proyecto social?',
    //     name: 'social_project',
    // },
    // {
    //     value: 'Observaciones',
    //     name: 'observations',
    // },
    {
        value: 'Fecha fin',
        name: 'end_date',
    },
    {
        value: 'Fecha inicio',
        name: 'start_date',
    },
    // {
    //     value: 'Identificación docente',
    //     name: 'educative_id',
    // },
    // {
    //     value: 'Nombre docente',
    //     name: 'educative_first_name',
    // },
    // {
    //     value: 'Apellido docente',
    //     name: 'educative_last_name',
    // },
    // {
    //     value: 'Celular del docente',
    //     name: 'educative_cellphone',
    // },
    // {
    //     value: 'Correo del docente',
    //     name: 'educative_email',
    // },
];

export const groupBeneficiariesFields = [
    {
        value: 'Estado',
        name: 'status',
    },
    {
        value: 'Código',
        name: 'code',
    },
    {
        value: 'Nombre del grupo',
        name: 'name_group',
    },
    {
        value: 'Colegio',
        name: 'school',
    },
    {
        value: 'Identificación del beneficiario',
        name: 'beneficiary_id',
    },
    {
        value: 'Nombre del beneficiario',
        name: 'beneficiary_first_name',
    },
    {
        value: 'Apellido del beneficiario',
        name: 'beneficiary_last_name',
    },
    {
        value: 'Estado teórico',
        name: 'beneficiary_user_status',
    },
    {
        value: 'Estado de nivelación',
        name: 'beneficiary_level_status',
    },
    {
        value: 'Estado práctico',
        name: 'beneficiary_practice_status',
    },
];

export const operationalUserFields = [
    {
        value: 'Tipo de Usuario',
        name: 'type',
    },
    {
        value: 'Identificación',
        name: 'username',
    },
    {
        value: 'Nombre',
        name: 'first_name',
    },
    {
        value: 'Apellido',
        name: 'last_name',
    },
    {
        value: 'Fecha de nacimiento',
        name: 'birthday',
    },
    {
        value: 'Sexo',
        name: 'gender',
    },
    {
        value: 'Correo institucional',
        name: 'email',
    },
    {
        value: 'Indicativo de celular',
        name: 'indicative',
    },
    {
        value: 'Celular',
        name: 'cellphone',
    },
    {
        value: 'Facebook',
        name: 'facebook',
    },
    {
        value: 'Instagram',
        name: 'instagram',
    },
    {
        value: 'Twitter',
        name: 'twitter',
    },
    {
        value: 'País',
        name: 'country',
    },
    {
        value: 'Departamento',
        name: 'department',
    },
    {
        value: 'Municipio',
        name: 'city',
    },
    {
        value: 'Dirección',
        name: 'address',
    },
    {
        value: 'Nivel de estudios',
        name: 'level_studies',
    },
    {
        value: 'Profesión',
        name: 'profession',
    },
    {
        value: 'Pensión',
        name: 'retirement',
    },
    {
        value: 'EPS',
        name: 'eps',
    },
    {
        value: 'Cesantías',
        name: 'severance_funds',
    },
    {
        value: 'Área de trabajo',
        name: 'work_area',
    },
    {
        value: 'Posición de trabajo',
        name: 'work_position',
    },
    {
        value: 'Ubicación',
        name: 'headquarters',
    },
    {
        value: 'Jefe Inmediato',
        name: 'immediate_boss',
    },
    {
        value: 'Función equipo Iwoka',
        name: 'rol_in_iwoka',
    },
    {
        value: 'Colegio',
        name: 'school',
    },
    {
        value: 'Fecha de admisión',
        name: 'admission_date',
    },
    {
        value: 'Fecha de egreso',
        name: 'egress_date',
    },
    {
        value: 'Estado',
        name: 'status',
    },
];

export const sessionFields = [
    {
        value: 'Titulo del módulo',
        name: 'module',
    },
    {
        value: 'Código',
        name: 'code',
    },
    {
        value: 'Título',
        name: 'title',
    },
    {
        value: 'Resumen',
        name: 'resume',
    },
    {
        value: 'Tipo de Programa',
        name: 'type_program'
    },
    {
        value: 'Nivel',
        name: 'level'
    }
];

export const trainingGroupFields = [
    {
        value: 'Nombre',
        name: 'name',
    },
    {
        value: 'Grupo de beneficiarios',
        name: 'group',
    },
    {
        value: 'Mentor',
        name: 'mentor',
    },
    {
        value: 'Monitor',
        name: 'monitor',
    },
    {
        value: 'Tipo de grupo',
        name: 'group_type',
    },
];

export const trainingPlanFields = [
    {
        value: 'Nombre',
        name: 'name',
    },
    {
        value: 'Nivel',
        name: 'level',
    },
    {
        value: '¿Qué será la última sesión?',
        name: 'last_session',
    },
    {
        value: 'Código de sesión',
        name: 'session_code',
    },
    {
        value: 'Titulo de sesión',
        name: 'session_title',
    },
    {
        value: 'Tipo de sesión',
        name: 'session_type',
    },
];

export const inventoryFields = [
    {
        value: 'Código',
        name: 'code',
    },
    {
        value: 'Descripción',
        name: 'description',
    },
    {
        value: 'Serie',
        name: 'serial_number',
    },
    {
        value: 'Ubicación',
        name: 'location',
    },
    {
        value: 'Sub Ubicación',
        name: 'sub_location',
    },
    {
        value: 'Costo',
        name: 'cost',
    },

    {
        value: 'category',
        name: 'category',
    },
    {
        value: 'Tipo Movimiento',
        name: 'movement_type',
    },
    {
        value: 'Stock Anetrior',
        name: 'last_stock',
    },
    {
        value: 'Cantidad',
        name: 'amount',
    },
    {
        value: 'Stock actual',
        name: 'stock',
    },
    {
        value: 'Fecha de Movimiento',
        name: 'movement_date',
    },
    {
        value: 'Ingresado por',
        name: 'last_user_movement',
    },
];

export const checkbox_filters = {
    calendar: calendarFields,
    beneficiary: beneficiaryFields,
    schools: schoolFields,
    beneficiary_groups: groupBeneficiariesFields,
    operational_user: operationalUserFields,
    session: sessionFields,
    training_plan: trainingPlanFields,
    training_group: trainingGroupFields,
    inventory: inventoryFields,
};
